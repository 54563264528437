import React from 'react';
import PropTypes from 'prop-types';
import NewBankAccountsList from '@fingo/lib/components/lists/NewBankAccountList';
import { Box, Typography, Stack, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Circle from '@mui/icons-material/Circle';

const AddInvoicesToPurchaseOrdersDialogStepTwo = ({
  statistics,
  accounts,
  loading,
  account,
  setAccount,
  company,
  refetch,
  toggleIsExternal,
  isExternal,
}) => {
  const missingDigitalCertificate = !company.hasDigitalCertificate;
  const missingInvoiceProviderCredentials = !company.hasInvoiceProviderCredentials;
  const disableFingoCession = missingDigitalCertificate || missingInvoiceProviderCredentials;
  return (
    <>
      <Stack spacing={1} alignItems="center">
        {statistics.map(({ label, value }) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="60%"
            key={label}
          >
            <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{label}:</Typography>
            <Typography sx={{ fontSize: 14 }}>{value}</Typography>
          </Stack>
        ))}
      </Stack>
      <Stack width="inherit">
        <Typography sx={{ fontWeight: 'bold', fontSize: 16, mt: 4 }}>Selecciona una cuenta bancaria para poder operar:</Typography>
        <NewBankAccountsList
          accounts={accounts}
          loading={loading}
          company={company}
          withSelect
          selectedAccount={account}
          setSelectedAccount={setAccount}
          addable={!accounts.length}
          editable={false}
          deletable={false}
          xs={12}
          refetch={refetch}
        />
        {!disableFingoCession && (
          <FormControl
            fullWidth
            margin="dense"
            sx={{ mt: 2 }}
          >
            <FormLabel id="cession-type">¿Como deseas ceder tu factura?</FormLabel>
            <RadioGroup
              value={isExternal}
              name="cession-options"
              onChange={toggleIsExternal}
            >
              <FormControlLabel
                value
                control={<Radio checkedIcon={<Circle />} />}
                label="Prefiero hacerlo yo, manualmente, desde mi facturador"
              />
              <FormControlLabel
                value={false}
                control={<Radio checkedIcon={<Circle />} />}
                label="Quiero que Fingo lo haga por mi"
                disabled={disableFingoCession}
              />
            </RadioGroup>
          </FormControl>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', width: 'inherit', alignSelf: 'center' }}>
          <WarningAmberIcon />
          <Typography ml={1}>
            Los montos finales están sujetos a la evaluación de riesgo y la fecha de operación.
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

AddInvoicesToPurchaseOrdersDialogStepTwo.propTypes = {
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  account: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  company: PropTypes.shape().isRequired,
  setAccount: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  toggleIsExternal: PropTypes.func.isRequired,
  isExternal: PropTypes.bool.isRequired,
};

export default AddInvoicesToPurchaseOrdersDialogStepTwo;
