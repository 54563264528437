import React from 'react';
import PropTypes from 'prop-types';
import { useIsMobile } from '@fingo/lib/hooks';
import {
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import AccessTime from '@mui/icons-material/AccessTime';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import makeStyles from '@mui/styles/makeStyles';
import { statusToStepLabels, statusToActiveStep } from '../../helpers/orderingStatus';

export const statusToIcon = {
  EVALUATION: <AccessTime color="warning" />,
  REJECTED: <ErrorOutline color="error" />,
  OFFERED: <CheckCircleOutline color="success" />,
  PENDINGASSIGNMENTDOCUMENT: <AccessTime color="warning" />,
  PENDINGSIGNATURE: <AccessTime color="warning" />,
  PENDINGTRANSFER: <AccessTime color="warning" />,
  PENDINGCOLLECTION: <AccessTime color="warning" />,
  PENDINGINVOICES: <AccessTime color="warning" />,
  FACTORING: <AccessTime color="warning" />,
  FINISHED: <CheckCircleOutline color="success" />,
};

const useStyles = makeStyles({
  stepper: {
    padding: 0,
    width: '100%',
    overflowX: 'hidden',
  },
  stepLabel: {
    marginTop: '4px !important',
    fontSize: 12,
  },
  collectionActionsButton: {
    fontSize: 12,
    height: 0,
  },
});

const getStatusIcon = (status, i) => {
  if (i < statusToActiveStep[status]) return <CheckCircleOutline color="success" />;
  if (statusToActiveStep[status] === i) return statusToIcon[status];
  return <RadioButtonUnchecked />;
};
const OrderingStatus = ({ status }) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  return (
    <Stepper className={classes.stepper} activeStep={statusToActiveStep[status]} alternativeLabel>
      {statusToStepLabels(status).map((label, i) => (
        ((isMobile && i === statusToActiveStep[status]) || !isMobile) && (
          <Step key={label}>
            <StepLabel
              icon={getStatusIcon(status, i)}
              classes={{
                label: classes.stepLabel,
              }}
            >
              {label} <br />
            </StepLabel>
          </Step>
        )))}
    </Stepper>
  );
};

OrderingStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default OrderingStatus;
