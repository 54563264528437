import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import { SEND_ORDER_TO_COLLECTION } from '@fingo/lib/graphql';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { formatMoney, addMoneyWithCurrency } from '@fingo/lib/helpers';
import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import FingoBankAccount from '@fingo/lib/components/text/FingoBankAccount';

const moneyTypography = (title, money, fontWeight) => (
  <>
    <Typography fontSize={15} fontWeight={fontWeight} align="center" width={150}>
      <Typography fontSize={15} fontWeight={fontWeight} align="center" color="primary">
        {title}: <br />
      </Typography>
      ${formatMoney(money)}
    </Typography>
  </>
);

const TransferBankDialog = ({
  open,
  toggleOpen,
  purchaseOrders,
  setSelectedPurchaseOrderIds,
}) => {
  const fontWeight = 300;
  const [checked, toggleChecked, , setCheckedFalse] = useBooleanState(false);
  const { addAlert } = useSnackBars();

  const handleClose = () => { setCheckedFalse(); toggleOpen(); };

  const debt = purchaseOrders?.reduce(
    (acc, cur) => addMoneyWithCurrency(acc, (cur.orderingDebt?.debt || 0)),
    0,
  );
  const orderingFinancedAmount = purchaseOrders?.reduce(
    (acc, cur) => addMoneyWithCurrency(acc, (cur.orderingoffer.orderingFinancedAmount || 0)),
    0,
  );
  const depositAmount = addMoneyWithCurrency(debt, orderingFinancedAmount);

  const [sendOrderToCollection, { loading }] = useMutation(
    SEND_ORDER_TO_COLLECTION,
    {
      variables: {
        purchaseOrderIds: purchaseOrders?.map((purchaseOrder) => purchaseOrder.id),
      },
      onCompleted: () => {
        addAlert({
          id: 'send-order-to-collection',
          message: 'Se realizó la operación de forma exitosa!',
        });
        setSelectedPurchaseOrderIds([]);
        toggleOpen();
      },
    },
  );

  return (
    <FingoDialog
      open={open}
      handleClose={handleClose}
      title="Datos bancarios"
      buttons={<></>}
    >
      <FingoBankAccount />
      <Stack width="100%" spacing={1} alignItems="center" justifyContent="center" mt={6}>
        <Typography variant="h6" fontWeight={fontWeight} align="center">Monto a depositar</Typography>
        <Stack direction="row" alignItems="center">
          {moneyTypography('Financiado', orderingFinancedAmount, fontWeight)}
          <AddIcon sx={{ fontSize: 10 }} />
          {moneyTypography('Mora', debt, fontWeight)}
        </Stack>
        {moneyTypography('Total', depositAmount, fontWeight)}
        <FormControlLabel
          label={`Ya realicé la transferencia por $${formatMoney(depositAmount)} a la cuenta solicitada`}
          control={<Checkbox checked={checked} onChange={toggleChecked} />}
        />
        <LoadingButton
          disabled={!checked}
          variant="contained"
          color="primary"
          size="small"
          onClick={sendOrderToCollection}
          loading={loading}
        >
          Confirmar pago
        </LoadingButton>
      </Stack>
    </FingoDialog>
  );
};

TransferBankDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func,
  purchaseOrders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      orderingDebt: PropTypes.shape({
        id: PropTypes.string,
        debt: PropTypes.number,
      }),
      orderNumber: PropTypes.string.isRequired,
      orderingoffer: PropTypes.shape({
        id: PropTypes.string.isRequired,
        orderingAmount: PropTypes.number.isRequired,
        orderingRetentionRate: PropTypes.number.isRequired,
        orderingPaymentAmount: PropTypes.number.isRequired,
        orderingCommission: PropTypes.number.isRequired,
        orderingMonthlyRate: PropTypes.number.isRequired,
        orderingInterest: PropTypes.number.isRequired,
        factoringMonthlyRate: PropTypes.number.isRequired,
        factoringDefaultRate: PropTypes.number.isRequired,
        factoringRetentionRate: PropTypes.number.isRequired,
        orderingFinancedAmount: PropTypes.number.isRequired,
        factoringCommission: PropTypes.number.isRequired,
      }),
    }).isRequired,
  ).isRequired,
  setSelectedPurchaseOrderIds: PropTypes.func.isRequired,
};

TransferBankDialog.defaultProps = {
  toggleOpen: () => {},
};

export default TransferBankDialog;
