import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const OTPEmail = ({ email, setEmail }) => (
  <>
    <Typography fullWidth paragraph align="center" variant="body1">
      Ingresa tu email para ingresar a la plataforma
    </Typography>
    <Box width={250} mx="auto">
      <TextField
        variant="outlined"
        label="Email"
        fullWidth
        value={email}
        onChange={setEmail}
      />
    </Box>
    <Box width={250} mx="auto" mt={2}>
      <Typography fullWidth paragraph align="center" variant="caption">
        **Debes ingresar con el mismo email que ingresas a Office Banking
      </Typography>
    </Box>
  </>
);
OTPEmail.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
};
export default OTPEmail;
