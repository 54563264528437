import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import StarRateIcon from '@mui/icons-material/StarRate';
import LazyLoadImage from '../image/LazyLoadImage';
import BulletList from '../lists/BulletList';

const CessionTypeOption = ({
  src,
  recommended,
  label,
  description,
  subItems,
  footer,
}) => (
  <Box display="flex" flexDirection="row" alignItems="flex-start">
    <LazyLoadImage src={src} alt={label} width={65} height={30} />
    <Box display="flex" flexDirection="column" ml={3}>
      <Box display="flex" alignItems="center">
        <Typography variant="h6" component="span" fontWeight="bold">
          {label}
        </Typography>
        {recommended && (
          <Chip
            icon={<StarRateIcon />}
            label="Recomendado"
            size="small"
            sx={{
              backgroundColor: 'primary.main',
              color: '#FFFFFF',
              marginLeft: 1.5,
              marginTop: -1,
              fontSize: 11,
              fontWeight: 400,
              '& .MuiChip-icon': {
                color: 'white',
                fontSize: 11,
              },
            }}
          />
        )}
      </Box>
      <BulletList
        title={description}
        titleProps={{
          variant: 'body2',
          color: recommended ? 'primary.main' : 'default',
        }}
        items={subItems.map(({ id, text, checked }) => ({
          id,
          primary: (
            <Stack direction="row">
              <Typography variant="body2" fontSize={11}>
                {text}
              </Typography>
              {checked && (
                <Typography
                  variant="body2"
                  fontSize={11}
                  color={green[500]}
                  ml={0.5}
                >
                  (Listo)
                </Typography>
              )}
            </Stack>
          ),
          color: 'primary.main',
        }))}
        sx={{ mt: '1px' }}
        disablePadding
        dense
      />
      {footer && (
        <Typography
          variant="caption"
          mt={2}
          fontSize={12}
          sx={{ opacity: 0.9 }}
        >
          {footer}
        </Typography>
      )}
    </Box>
  </Box>
);

CessionTypeOption.propTypes = {
  src: PropTypes.string,
  recommended: PropTypes.bool,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  subItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      checked: PropTypes.bool,
      text: PropTypes.string,
    }),
  ),
  footer: PropTypes.string,
};

CessionTypeOption.defaultProps = {
  recommended: false,
  src: null,
  footer: null,
  subItems: [],
};

export default React.memo(CessionTypeOption);
