import React from 'react';
import PropTypes from 'prop-types';
import { NPS } from '../dialogs';

const NpsProvider = ({ children, npsId, presetGrade, timeout }) => (
  <>
    <NPS npsId={npsId} presetGrade={presetGrade} timeout={timeout} />
    {children}
  </>
);

NpsProvider.propTypes = {
  children: PropTypes.element.isRequired,
  npsId: PropTypes.string,
  presetGrade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timeout: PropTypes.number,
};

NpsProvider.defaultProps = {
  npsId: '',
  presetGrade: 0,
  timeout: 5000,
};

export default NpsProvider;
