import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack, Link } from '@mui/material';

const OrderingDebtWarning = ({ orderingDebt, hasPurchaseOrdersNearToDebt }) => {
  const shouldShow = useMemo(
    () => orderingDebt > 0 || hasPurchaseOrdersNearToDebt,
    [orderingDebt, hasPurchaseOrdersNearToDebt],
  );

  const title = useMemo(() => {
    if (!!orderingDebt && hasPurchaseOrdersNearToDebt) {
      return 'Tienes OCs vencidas y OCs que vencen en 5 días';
    }
    if (orderingDebt) {
      return 'Tienes OCs vencidas';
    }
    if (hasPurchaseOrdersNearToDebt) {
      return 'Tienes OCs que vencen en 5 días';
    }
    return null;
  }, [orderingDebt, hasPurchaseOrdersNearToDebt]);

  if (!shouldShow) return null;

  return (
    <Stack
      sx={{
        bgcolor: 'background.light',
        '& .MuiAccordionSummary-content': {
          justifyContent: 'space-between',
        },
        py: 3,
        px: 2,
      }}
      id="ordering-debt-warning"
      direction="row"
      justifyContent="space-between"
    >
      <Typography variant="h6">
        {title},{' '}
        <Link href="/app/sales/ordering/operations" color="primary">
          págalas aquí
        </Link>
      </Typography>
    </Stack>
  );
};

OrderingDebtWarning.propTypes = {
  orderingDebt: PropTypes.number.isRequired,
  hasPurchaseOrdersNearToDebt: PropTypes.bool.isRequired,
};

export default OrderingDebtWarning;
