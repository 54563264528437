import React from 'react';
import { useIsMobile, useSelectedCompany } from '@fingo/lib/hooks';
import { PartnerAceptaLogo, PartnerSenegociaLogo, PartnerManagerLogo } from '@fingo/lib/assets';
import { Box } from '@mui/material';

const PartnerLogo = () => {
  const isMobile = useIsMobile();
  const selectedCompany = useSelectedCompany();
  const conditionToProperties = {
    isAcepta: {
      alt: 'Partner de acepta',
      src: PartnerAceptaLogo,
    },
    isSenegocia: {
      alt: 'Partner de Senegocia',
      src: PartnerSenegociaLogo,
    },
    isManager: {
      alt: 'Partner de Manager',
      src: PartnerManagerLogo,
    },
  };

  let selectedCondition = null;

  if (selectedCompany?.isAcepta) {
    selectedCondition = 'isAcepta';
  } else if (selectedCompany?.isSenegocia) {
    selectedCondition = 'isSenegocia';
  } else if (selectedCompany?.isManagermas) {
    selectedCondition = 'isManager';
  }
  const selectedProperties = conditionToProperties[selectedCondition];

  return (
    <>
      {selectedProperties && (
      <Box
        alignSelf="center"
        border="solid 0px darkblue"
        component="img"
        width={isMobile ? 180 : 250}
        height="auto"
        alt={selectedProperties.alt}
        src={selectedProperties.src}
      />
      )}
    </>

  );
};

export default PartnerLogo;
