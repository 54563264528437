import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useBooleanState } from '@fingo/lib/hooks';
import usePurchaseOrders from '@fingo/lib/hooks/usePurchaseOrders';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import Send from '@mui/icons-material/Send';
import TransferBankDialog from './dialogs/TransferBankDialog';
import AddInvoicesToPurchaseOrdersDialog from './dialogs/AddInvoicesToPurchaseOrdersDialog';

import PorftolioDownload from '../../factoring/PorfolioDownload';

const OrderingOperationActions = ({
  selectedPurchaseOrderIds,
  setSelectedPurchaseOrderIds,
  company,
  refetch,
  hidePay,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openExternalCession, toggleExternalCession, , , setOpen] = useBooleanState(false);
  const [openTransfer, toggleTransfer] = useBooleanState(false);
  const [portfolioOpen, togglePortfolio] = useBooleanState(false);

  const { purchaseOrders } = usePurchaseOrders({
    skip: !selectedPurchaseOrderIds.length,
    variables: { id_In: selectedPurchaseOrderIds },
  });

  const menuItemPush = useCallback((runFunction) => () => {
    setAnchorEl(null);
    runFunction();
  }, [setAnchorEl]);

  return (
    <>
      <PorftolioDownload open={portfolioOpen} onClose={togglePortfolio} product="ORDERING" onlyHistoric />
      <Button
        variant="contained"
        color="primary"
        size="small"
        disableElevation
        onClick={togglePortfolio}
        id="toggle-download-portfolio"
      >
        Descargar resumen
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={menuItemPush(toggleExternalCession)}
        id="purchaseOrderPay"
        disabled={!purchaseOrders.length}
        sx={{
          display: hidePay ? 'none' : 'inherit',
        }}
      >
        Pagar
      </Button>
      <Menu
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        elevation={1}
        keepMounted={false}
      >
        <MenuItem
          onClick={menuItemPush(toggleExternalCession)}
          id="orderingPayWithInvoice"
        >
          <ListItemIcon>
            <Send fontSize="medium" color="primary" />
          </ListItemIcon>
          <ListItemText>
            Pagar cediendo factura
          </ListItemText>
        </MenuItem>
        {/* <Divider /> */}
        {/* <MenuItem
          onClick={menuItemPush(toggleTransfer)}
          id="orderingPayWithTransfer"
        >
          <ListItemIcon>
            <Check fontSize="medium" color="primary" />
          </ListItemIcon>
          <ListItemText>
            Pagar con transferencia bancaria
          </ListItemText>
        </MenuItem> */}
      </Menu>
      {openExternalCession && (
        <AddInvoicesToPurchaseOrdersDialog
          open={openExternalCession}
          setOpen={setOpen}
          purchaseOrders={purchaseOrders}
          setSelectedPurchaseOrderIds={setSelectedPurchaseOrderIds}
          company={company}
          refetch={refetch}
        />
      )}
      {openTransfer && (
        <TransferBankDialog
          open={openTransfer}
          toggleOpen={toggleTransfer}
          purchaseOrders={purchaseOrders}
          setSelectedPurchaseOrderIds={setSelectedPurchaseOrderIds}
        />
      )}
    </>
  );
};

OrderingOperationActions.propTypes = {
  selectedPurchaseOrderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedPurchaseOrderIds: PropTypes.func.isRequired,
  company: PropTypes.shape({
    rut: PropTypes.string,
  }).isRequired,
  refetch: PropTypes.func,
  hidePay: PropTypes.bool,
};

OrderingOperationActions.defaultProps = {
  hidePay: false,
  refetch: () => {},
};

export default OrderingOperationActions;
