import React, { useState, useRef, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

const BoxInput = ({ onChange, onCompleted, length }) => {
  const keys = Array.from(Array(length).keys());
  const [values, setValues] = useState(Array(length).fill(''));
  const inputRefs = useRef([]);
  const focusNextInput = useCallback((currentIndex) => {
    if (currentIndex < length - 1) {
      inputRefs.current[currentIndex + 1].focus();
    }
  }, []);
  const focusPreviousInput = useCallback((currentIndex) => {
    if (currentIndex > 0) {
      inputRefs.current[currentIndex - 1].focus();
    }
  }, []);
  const isValueCompleted = useCallback((newValues) => newValues.length === length, []);
  const handleChange = useCallback((index) => (event) => {
    const newValue = event.target.value;
    setValues((previousValues) => {
      const newValues = [...previousValues];
      newValues[index] = newValue.slice(-1);
      const stringValue = newValues.join('');
      onChange(stringValue);
      if (index === length - 1 && isValueCompleted(stringValue)) {
        onCompleted(stringValue);
      }
      return newValues;
    });
  }, []);

  const onKeyUp = useCallback((index) => (ev) => {
    const newValue = ev.target.value;
    if (newValue !== '') {
      focusNextInput(index);
    } else {
      focusPreviousInput(index);
    }
  }, []);

  const handlePaste = useCallback((event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').trim();
    if (pasteData.length !== length) return;
    const newValues = pasteData.slice(0, length);
    setValues([...newValues]);
    onChange(newValues);
    if (isValueCompleted(newValues)) {
      onCompleted(newValues);
    }
  }, []);

  const inputChange = useCallback((index) => (ev) => {
    inputRefs.current[index] = ev;
  }, []);
  return (
    <Grid container alignItems="center" columnSpacing={1}>
      {values.map((value, index) => (
        <Grid item xs={2} key={keys[index]}>
          <TextField
            inputRef={inputChange(index)}
            onKeyUp={onKeyUp(index)}
            value={value}
            onChange={handleChange(index)}
            onPaste={handlePaste}
            size="small"
            InputProps={{
              sx: {
                minHeight: 35,
                height: 35,
              },
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

BoxInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
  length: PropTypes.number,
};

BoxInput.defaultProps = {
  onCompleted: () => {},
  length: 6,
};

export default BoxInput;
